import $ from "jquery";

$(window).on('load', function(){
  $('#currentYear').html(new Date().getFullYear())
});

$('.js-project').on('click', function() {
  let parent = $(this).parent();
  if (!$(this).hasClass('project-active')) {
      parent.find('.project-active').removeClass('project-active');
      $(this).addClass('project-active');
  }
})

$('.tab').each(function (index) {
    $(this).on("click", function () {
        if (!$(this).hasClass('tab-active')) {
            const slide = $('.slide').get(index);
            $('.tab-active').removeClass('tab-active');
            $('.slide-active').removeClass('slide-active');
            $(slide).addClass('slide-active');
            $(this).addClass('tab-active');
        }
    });
});

const burger = document.querySelector('.js-burger-menu');
const navLinks = document.querySelector('.js-navbar-links');

burger.addEventListener('click', () => {
    document.querySelector('body').classList.toggle('no-scroll');
    burger.classList.toggle('is-active');
    navLinks.classList.toggle('navbar__links--active');
})

var navDropDowns = document.querySelectorAll('.js-nav-links-container');

document.querySelectorAll('.js-nav-links-header').forEach(function (value, i) {
    value.addEventListener('click', function() {
      navDropDowns.forEach(function(val, j) {
        if(j === i) {
          navDropDowns[i].classList.toggle('nav-links__container--shown');
        }
        else {
          navDropDowns[j].classList.remove('nav-links__container--shown');
        }
      })

      let navbarElement = document.querySelector('.js-navbar');
      if(!navbarElement.classList.contains('navbar--static')){
        if(document.querySelector('.nav-links__container--shown')) {
          navbarElement.classList.remove('navbar--transparent');
        }
        else if(lastKnownScrollPosition < 50 && document.querySelector()){
          navbarElement.classList.add('navbar--transparent');
        }
      }
     
      
    });
});


let lastKnownScrollPosition = 0;
let ticking = false;

function changeNavBar(scrollPos) {
  let navbarElement = document.querySelector('.js-navbar');
  if(!navbarElement.classList.contains('navbar--static')){
    if(scrollPos > 50) {
      document.querySelector('.js-navbar').classList.remove('navbar--transparent');
    }
    else if(!document.querySelector('.nav-links__container--shown')){
      document.querySelector('.js-navbar').classList.add('navbar--transparent');
    }
  }
}

document.addEventListener('scroll', function(e) {
  lastKnownScrollPosition = window.scrollY;

  if (!ticking) {
    window.requestAnimationFrame(function() {
      changeNavBar(lastKnownScrollPosition);
      ticking = false;
    });

    ticking = true;
  }
});

// handle links with @href with '#'
$(document).on('click', 'a[href*="#"]', function(e) {
  var id = $(this).attr('href');
  console.log(id)

  var $id = $(id);
  if ($id.length === 0) {
      return;
  }

  e.preventDefault();

  // top position relative to the document
  var pos = $id.offset().top -75;
  console.log(pos)

  // animated top scrolling
  $('body, html').animate({scrollTop: pos},100, 'linear');
});


let applyForPositionLink = document.querySelectorAll(".js-select-position");
if(applyForPositionLink){
  let select = document.querySelector('#positions')
  console.log(applyForPositionLink)
  applyForPositionLink.forEach(element => {
    element.addEventListener('click', function(){
      console.log(element)
      select.value = element.getAttribute('data-position');
    })
  });
}